import React from 'react'
import PropTypes from 'prop-types'

const ProductCategory = ({ name, spacer }) => (
    <div className="product-category-name">
        <div className={spacer ? 'product-category-name-spacer' : ''} />
        <p>{name}</p>
    </div>
)

ProductCategory.propTypes = {
  name: PropTypes.string.isRequired,
  spacer: PropTypes.bool,
}

ProductCategory.defaultProps = {
  spacer: false,
}

export default ProductCategory
