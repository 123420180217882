import React from 'react'
import PropTypes from 'prop-types'
import { useI18next } from 'gatsby-plugin-react-i18next'

const News = ({ children }) => {
  const { t } = useI18next()

  return (
      <div className="page-section">
          <div className="page-section-title">
              <p>{t('section-news').toUpperCase()}</p>
          </div>
          <div className="page-section-content">
              <div className="page-section-content-news">
                  { children }
              </div>
          </div>
      </div>
  )
}

News.propTypes = {
  children: PropTypes.node.isRequired,
}

export default News
