import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const NewsTenderCard = ({
  title, tenderPic, description, path,
}) => {
  const data = useStaticQuery(graphql`
    query NewsTenderCardImages {
        images: allFile(filter: { relativeDirectory: { eq: "news" } } ) {
            nodes {
                id
                childImageSharp {
                    fluid {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
  `)

  const image = data.images.nodes.find((n) => n.childImageSharp.fluid.src.includes(tenderPic))
  return (
      <div className="news-tender-card">
          <a href={path} target="_blank" rel="noreferrer">
              <div className="news-tender-card-header">
                  <div className="news-tender-card-header-img-wrapper">
                      <Img fluid={image.childImageSharp.fluid} />
                  </div>
                  <div className="news-tender-card-header-title">
                      <p>{title}</p>
                  </div>
              </div>
              <div className="news-tender-card-info">
                  <p>{description}</p>
              </div>
          </a>
      </div>
  )
}

NewsTenderCard.propTypes = {
  title: PropTypes.string.isRequired,
  tenderPic: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  path: PropTypes.string,
}

NewsTenderCard.defaultProps = {
  path: null,
}

export default NewsTenderCard
