import React from 'react'
import { useTranslation, I18nContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Background from '../../components/background'
import Products from '../../components/products'
import ProductCategory from '../../components/product_category'
import ProductCard from '../../components/product_card'
import News from '../../components/news'
import NewsTenderCard from '../../components/news_tender_card'

const SGAPage = () => {
  const { i18n } = React.useContext(I18nContext)
  const { t } = useTranslation()

  return (
      <Layout>
          <SEO title={t('sga-title')} language={i18n.language} />
          <Background title={t('division-telecommunication')} backgroundTextPic="bg-section-background-sga" translationKey="sga-background-card-list" big />
          <Products>
              <ProductCategory name={t('sga-telecom-network-monitoring')} />
              <ProductCard productName="SGA-NETMON" productPic="SGA-NETMON" translationKey="sga-netmon-card-list" path="/products/sga-netmon.pdf" />
              <ProductCard productName="SGA-10GEQ" productPic="SGA-10GEQ" translationKey="sga-10geq-card-list" path="/products/sga-10geq.pdf" />
              <ProductCard productName="SGA-AntiSpy" productPic="SGA-AntiSpy" translationKey="sga-anti-spy-card-list" path="/products/sga-anti-spy.pdf" />
              <ProductCard productName="SGA-GPRS" productPic="SGA-GPRS" translationKey="sga-gprs-card-list" />
              <ProductCard productName="SGA-7TA" productPic="SGA-7TA" translationKey="sga-7ta-card-list" path="/products/sga-7ta.pdf" />
              <ProductCard productName="SGA-FPGA Networking" productPic="SGA-FPGA" translationKey="sga-fpga-card-list" path="http://www.fpganetworking.com" />
              <ProductCard productName="SGA-ClockCard" productPic="SGA-ClockCard" translationKey="sga-clockcard-card-list" path="http://www.fpganetworking.com/150701/sga_clock/index.html" />
              <ProductCard productName="SGA-C-GEP" productPic="SGA-C-GEP" translationKey="sga-c-gep-card-list" path="http://www.fpganetworking.com/150701/c_gep/index.html" />

              <ProductCategory name={t('sga-roaming-services')} spacer />
              <ProductCard productName="Roaming Testers" productPic="SGA-RoamingTesters" translationKey="sga-roaming-testers-card-list" path="/products/sga-wt.pdf" />
              <ProductCard productName="Roaming Steering Service" productPic="SGA-RSS" translationKey="sga-rss-card-list" path="/products/sga-rss.pdf" />
              <ProductCard productName="Intelligent Call Assistant" productPic="SGA-ICA" translationKey="sga-ica-card-list" path="/products/sga-ica.pdf" />
              <ProductCard productName="Welcome and Bon-Voyage SMS" productPic="SGA-WSMS" translationKey="sga-wsms-card-list" />
              <ProductCard productName="Free Divert To Voice Mail" productPic="SGA-FDTVM" translationKey="sga-fdtvm-card-list" path="/products/sga-fdtvm.pdf" />

              <ProductCategory name={t('sga-core-and-value-added-services')} spacer />
              <ProductCard productName="DDoS Attack Detection Engine" productPic="SGA-NEDD" translationKey="sga-nedd-card-list" path="/products/sga-nedd.pdf" />
              <ProductCard productName="Complex Traffic Generators" productPic="SGA-TG" translationKey="sga-tg-card-list" path="/products/sga-tg.pdf" />
              <ProductCard productName="Equipment Identity Register" productPic="SGA-EIR" translationKey="sga-eir-card-list" path="/products/sga-eir.pdf" />
              <ProductCard productName="USSD Gateway" productPic="SGA-USSD" translationKey="sga-ussd-card-list" path="/products/sga-ussd.pdf" />
              <ProductCard productName="Missed Call Notification" productPic="SGA-CN" translationKey="sga-cn-card-list" path="/products/sga-cn.pdf" />
              <ProductCard productName="MAP Firewall" productPic="SGA-MAPFW" translationKey="sga-mapfw-card-list" path="/products/sga-mapfw.pdf" />

              <ProductCategory name={t('sga-sms-distribution-and-control')} spacer />
              <ProductCard productName="Home Router for SMS Service" productPic="SGA-HR" translationKey="sga-hr-card-list" path="/products/sga-hr.pdf" />
              <ProductCard productName="SMS Fraud Detection System" productPic="SGA-SMSFD" translationKey="sga-smsfd-card-list" path="/products/sga-smsfd.pdf" />
              <ProductCard productName="Mass SMS Receiving System" productPic="SGA-MSMS" translationKey="sga-msms-card-list" />
          </Products>
          <News>
              <NewsTenderCard title={t('news-networked-sky-project-title')} tenderPic="national-research" description={t('news-networked-sky-project-desc')} path="/projects/networked-sky" />
              <NewsTenderCard title="CoreMoRen Project" tenderPic="national-research" description={t('news-coremoren-project-desc')} path="/projects/cormoren" />
              <NewsTenderCard title="Traffic Generator for Complex Telecommunication Systems" tenderPic="szechenyi-terv" description={t('news-tafor-project-desc')} path="/projects/tafor" />
          </News>
      </Layout>
  )
}

export default SGAPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "sga", "products", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
