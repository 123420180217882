import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import CardList from './cardlist'

const ProductCard = ({
  productName, productPic, productDesc, path, internal, translationKey,
}) => {
  const data = useStaticQuery(graphql`
    query ProductCardImages {
        images: allFile(filter: { relativeDirectory: { eq: "products" } } ) {
            nodes {
                id
                childImageSharp {
                    fluid {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
  `)

  const image = data.images.nodes.find((n) => n.childImageSharp.fluid.src.includes(productPic))

  const renderProductDescription = () => {
    if (productDesc) {
      return <p>{productDesc}</p>
    }

    if (translationKey) {
      return <CardList translationKey={translationKey} ulClassName="product-card-details-description-list" />
    }

    return ''
  }

  return (
      <div className="product-card">
          { internal
            ? (
                <a href={path}>
                    <div className="product-card-img-wrapper">
                        <Img fluid={image.childImageSharp.fluid} />
                    </div>
                    <div className="product-card-details">
                        <p>{productName}</p>
                        <div className="product-card-details-description">
                            {renderProductDescription()}
                        </div>
                    </div>
                </a>
            )
            : (
                <a href={path} target="_blank" rel="noreferrer">
                    <div className="product-card-img-wrapper">
                        <Img fluid={image.childImageSharp.fluid} />
                    </div>
                    <div className="product-card-details">
                        <p>{productName}</p>
                        <div className="product-card-details-description">
                            {renderProductDescription()}
                        </div>
                    </div>
                </a>
            )}
      </div>
  )
}

ProductCard.propTypes = {
  productName: PropTypes.string.isRequired,
  productPic: PropTypes.string.isRequired,
  productDesc: PropTypes.string,
  translationKey: PropTypes.string,
  path: PropTypes.string,
  internal: PropTypes.bool,
}

ProductCard.defaultProps = {
  path: null,
  internal: false,
  productDesc: undefined,
  translationKey: undefined,
}

export default ProductCard
